@import "@fontsource/ubuntu";

html,
body {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-content: center;
  margin: 0;
  padding: 0;
  font-family: Ubuntu, Tahoma, sans-serif;
  font-size: 2vw;
  /* gap: 0.25em; */
  background: #222;
  color: #eee;
}

section {
  display: grid;
  place-content: center;
}

twisty-player {
  width: 90vw;
  height: 40vh;
  margin: auto;
  opacity: 0;
  transition: opacity 1s ease;
}

.cubing-icon {
  margin: auto;
  font-size: calc(min(10vw, 6vh));
  height: 1em;
  color: inherit;
}

a.cubing-icon,
#event-grid button,
#rescramble {
  transition: transform 0.2s ease, opacity 0.2s ease;
  text-decoration: none;
}

a.cubing-icon:hover,
a.cubing-icon:focus,
#event-grid button:hover,
#event-grid button:focus {
  transform: rotate(10deg);
  opacity: 0.5;
}

#event-selector {
  margin-bottom: 0.5em;
}

#text {
  margin: auto;
  text-align: center;
  min-height: 2em;
  max-width: 75vw;
  font-size: calc(min(10vw, 4vh));

  display: grid;
  place-content: center;
}

#text.event-minx {
  font-family: monospace;
  text-align: start;
  max-width: 95vw;
  font-size: calc(min(10vw, 3.5vh));
}

#text.event-555,
#text.event-555bf,
#text.event-666,
#text.event-777 {
  max-width: 90vw;
  font-size: calc(min(10vw, 3vh));
}

/* SVG */
#text.event-clock,
#text.event-sq1 {
  margin-bottom: 0.5em;
}

#generating {
  animation: pulse 1s infinite;
}

#options {
  padding: 0.25em;
  margin: 1em;
  padding: 1em;
  border: 1px solid #aaa;
  display: grid;
  /* grid-template-rows: repeat(4, 1fr); */
  gap: 0.5em;
}

.option-row {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  font-size: 1.5em;
}

.option-row.tight {
  justify-content: center;
  gap: 0.5em;
}

.option-row input[type="checkbox"] {
  margin: auto;
}

#event-grid {
  padding: 0.25em;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1em;
}

#event-grid button {
  background: none;
  border: none;
  height: 100%;
  padding: 0;
  /* Safari workaround */
}

#event-grid button:hover {
  cursor: pointer;
}

#options hr {
  width: 100%;
  height: 0px;
  border: none;
  border-top: 1px solid white;
}

#show-events .cubing-icon {
  padding: 0.25em;
}

@keyframes pulse {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.timer-link-span {
  text-align: center;
  font-size: 1.25em;
}

#options a {
  color: #669df5;
}

#options a:visited {
  color: #af73d5;
}

[hidden] {
  display: none !important;
}

#show-events:target {
  display: grid !important;
}

#rescramble {
  position: absolute;
  padding: 0.5em 0.25em 0 1em;
  bottom: 0;
  right: 0;
  font-size: calc(min(15vw, 15vh));
  color: inherit;
  text-decoration: none;
  touch-action: manipulation;
}

#rescramble:hover,
#rescramble:focus {
  opacity: 0.4;
}

#rescramble.force-show {
  display: block !important;
}

@media all and (display-mode: standalone) {
  #rescramble {
    display: block !important;
  }
}

@media (prefers-color-scheme: light) {
  html,
  body {
    background: #eee;
    color: #444;
  }

  #options hr {
    border-top: 1px solid #aaa;
  }
}
